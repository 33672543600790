import React from 'react'

const  NoPageFound = () => {
    return (
<div>
    <h1>NO PAGE  FOUND</h1>
</div>
    )
}

export default NoPageFound