import React from "react";
import NavBar from "../../component/Navbar/Navbar";
import "../landingPage/LandingPage.css";
import Envelop from "../../images/svgs/Envelope.svg";
import CopyIcon from "../../images/svgs/copyIconcantactus.svg";
import Swal from "sweetalert2";
const ContactUs = () => {
  // const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText("enquiries@owenamarketplace.com")
      .then(() => {
        // setCopied(true);
        // setTimeout(() => setCopied(false), 2000);
        Swal.fire({
          icon: "success",
          title: "Copied!",
          // text: 'The email address has been copied to the clipboard.',
          timer: 2000, // Auto close after 2 seconds
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to copy the email address. Please try again.",
          timer: 2000, // Auto close after 2 seconds
          showConfirmButton: false,
        });
      });
  };

  return (
    <div>
      <NavBar />
      <div className="heheight"></div>
      <div style={{ height: "50px" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <h1 style={{ color: "#F4AA06" }}>Contact Us</h1>
        <img src={Envelop} alt="Envelop" className="cccuummm" />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#FAFAFA",
            width: "100%",
            margin: "20px",
            padding: "20px",
          }}
        >
          <div style={{ width: "350px" }}>
            <p
              style={{
                color: "#666666",
                lineHeight: "30px",
                textAlign: "justify",
              }}
            >
              We would love to hear from you! At Owena, we value your feedback,
              questions, and inquiries. We are here to provide you with the best
              possible support and assistance. Please feel free to reach out to
              us by emailing
              <p style={{ color: "#454444", fontWeight: "600" }}>
                enquiries(at)owenamarketplace.com{" "}
                <span>
                  <img
                    src={CopyIcon}
                    alt="CopyIcon"
                    style={{ cursor: "pointer" }}
                    onClick={handleCopyClick}
                  />
                </span>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
