import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import NavBar from "../../component/Navbar/Navbar";
import groupImage from "../../images/GroupPng.png";
import Barcode1 from "../../images/barcode1.png";
import three3 from "../../images/threeImagepng.png";
import SmaillIcon from "../../images/svgs/smallIcon.svg";
import firstImage from "../../images/svgs/pascode.svg";
import secondImage from "../../images/svgs/secodImage.svg";
import thirdImage from "../../images/svgs/thirdimage.svg";
import fourthImage from "../../images/svgs/fourthImage.svg";
import firttyImage from "../../images/svgs/fitty.svg";
import smalllImage from "../../images/svgs/AnotherSmallIcon.svg";
import threimage from "../../images/lastgroup.png";
import GoogleStore from "../../images/svgs/apple-app-store-travel-awards-globesta.svg";
import AppleStore from "../../images/svgs/apple-app-store-travel-awards.svg";
import Oweone1 from "../../images/svgs/OWENA.svg";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
const LandingPage = () => {
  const texts = [
    "Shop Local & Multicultural<br>Products Easily & Conveniently",
    "Enjoy Smooth Shopping<br>Experience and Pay Easily",
    "Track Orders And Deliveries<br>Smoothly & Happily",
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  // const [responseMessage, setResponseMessage] = useState("");
  // const [currentNumber, setCurrentNumber] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      // setCurrentNumber((prevNumber) => (prevNumber % texts.length) + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [texts.length]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !isValidEmail(email)) {
      Swal.fire({
        title: "Failed!",
        html: "Please enter a valid email.",
        icon: "error",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });
      return;
    }
    setLoading(true);
    // Create a POST request using the fetch API
    fetch("https://api.owenamarketplace.com/api/v1/subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setEmail("");
          Swal.fire({
            title: "Success!",
            html: `<i>${data.status}</i>`,
            icon: "success",
            allowOutsideClick: false,
          });
        }
        setLoading(false);
        // setResponseMessage(data.message);
      })
      .catch((error) => {
        // Handle any errors here
        setLoading(false);
        Swal.fire({
          title: "Failed!",
          html: `<i>${error}</i>`,
          icon: "error",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      });
  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  // const isButtonDisabled = !email;

  const systemWidth = (width) => {
    const windowWidth = window.innerWidth;
    const widths = (width / 1440) * windowWidth;
    return `${widths}px`;
  };

  return (
    <div className="alldiv">
      <NavBar />
      <div style={{ height: "100px" }}></div>

      <div className="threeImage">
        <div style={{ width: systemWidth(95) }}></div>
        <div className="goupdivs">
          {/* <div style={{marginLeft: '-35px', marginTop: '3px',  display: 'flex', width: '64px', color: 'white', height: '64px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', backgroundColor: '#F4AA06', borderRadius: '50px' }}>
                        <h1 style={{ textAlign: 'center' }}>{currentNumber}</h1>
                    </div> */}
          <div style={{ marginTop: "50px" }}>
            <h1
              className="currentTextIndex"
              dangerouslySetInnerHTML={{ __html: texts[currentTextIndex] }}
            />
          </div>
          <div style={{ height: "12px" }}></div>
          <p className="currentTextIndexOwena">
            Owena is bringing your favourite stores closer to you.
            <br /> Connecting you to sellers in an Express & smoother way.
          </p>

          <div style={{ height: "51px" }}></div>
          <p className="soon">Coming soon on</p>
          <div style={{ height: "3px" }}></div>
          <div className="downloadApp">
            <div>
              <img src={AppleStore} alt="AppleStore" />
            </div>
            <div>
              <img src={GoogleStore} alt="GoogleStore" />
            </div>
          </div>
        </div>
        <div style={{ width: systemWidth(290) }}></div>
        <div className="goupdivsimage">
          <img className="imagesize" src={groupImage} alt="groupImage" />
        </div>
      </div>
      <div className="barcodeimagedesktop">
        <img className="barcodeimage" src={Barcode1} alt="barcode" />
      </div>

      <div className="yellowDesktop">
        <div
          style={{
            backgroundColor: "#F4AA06",
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          }}
        >
          {/* <div> */}
          <img src={three3} alt="threesimage" />
          {/* </div> */}
          <div className="amazing">
            <div style={{ height: "50px" }}></div>
            <div>
              <h1 className="shopping">
                Amazing <span className="shoppings">Shopping </span>
              </h1>
              <h1 className="experience">Experience</h1>
            </div>
            <div>
              <p></p>
              <p className="we">
                We are bringing high street stores closer to you.
              </p>
              <p className="we">
                {" "}
                You will be able to buy local and multicultural goods, Pay
              </p>
              <p className="we"> conveniently and get your orders same day.</p>
            </div>
            <div style={{ height: "100px" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="fivediv">
                <div className="divSizeww">
                  <img src={firstImage} alt="firstImage" />
                  <p className="testes">
                    Enter your
                    <br />
                    Postcode
                  </p>
                </div>
                <div className="divSizeww">
                  <img
                    className="imagesss"
                    src={secondImage}
                    alt="secondImage"
                  />
                  <p className="testes">
                    Browse <br /> Categories & <br />
                    Communities
                  </p>
                </div>
                <div className="divSizeww">
                  <img className="imagesss" src={thirdImage} alt="firstImage" />
                  <p className="testes">
                    Pick goods
                    <br /> from Stores
                    <br />
                    around you
                  </p>
                </div>
                <div className="divSizeww">
                  <img
                    className="imagesss"
                    src={fourthImage}
                    alt="fourthImage"
                  />
                  <p className="testes">
                    Pay
                    <br />
                    Seamlessly
                  </p>
                </div>
                <div className="divSizeww">
                  <img
                    className="imagesss"
                    src={firttyImage}
                    alt="firstImage"
                  />
                  <p className="testes">
                    Instant
                    <br /> Delivery
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "650px",
                  height: "23px",
                  display: "flex",
                  background:
                    "linear-gradient(180deg, #826448 0%, #F2D77A 100%)",
                  zIndex: "1",
                  position: "absolute",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="yellowTabLetDesktop">
        <div
          style={{
            backgroundColor: "#F4AA06",
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          }}
        >
          {/* <div> */}
          <img src={three3} alt="threesimage" />
          {/* </div> */}
          <div className="amazing">
            <div style={{ height: "50px" }}></div>
            <div>
              <h1 className="shoppingt">
                Amazing <span className="shoppingst">Shopping </span>
              </h1>
              <h1 className="experiencet">Experience</h1>
            </div>
            <div>
              <p></p>
              <p className="wet">
                We are bringing high street stores closer to you. You will be
                able to buy local and multicultural goods, Pay conveniently and
                get your orders same day.
              </p>
            </div>
            <div style={{ height: "100px" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="fivedivt">
                <div className="divSizewwt">
                  <img src={firstImage} alt="firstImage" />
                  <p className="testes">
                    Enter your
                    <br />
                    Postcode
                  </p>
                </div>
                <div className="divSizewwt">
                  <img
                    className="imagesss"
                    src={secondImage}
                    alt="secondImage"
                  />
                  <p className="testes">
                    Browse <br /> Categories & <br />
                    Communities
                  </p>
                </div>
                <div className="divSizewwt">
                  <img className="imagesss" src={thirdImage} alt="firstImage" />
                  <p className="testes">
                    Pick goods
                    <br /> from Stores
                    <br />
                    around you
                  </p>
                </div>
                <div className="divSizewwt">
                  <img
                    className="imagesss"
                    src={fourthImage}
                    alt="fourthImage"
                  />
                  <p className="testes">
                    Pay
                    <br />
                    Seamlessly
                  </p>
                </div>
                <div className="divSizewwt">
                  <img
                    className="imagesss"
                    src={firttyImage}
                    alt="firstImage"
                  />
                  <p className="testes">
                    Instant
                    <br /> Delivery
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "450px",
                  height: "23px",
                  display: "flex",
                  background:
                    "linear-gradient(180deg, #826448 0%, #F2D77A 100%)",
                  zIndex: "1",
                  position: "absolute",
                  marginRight: "90px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "50px" }}></div>
      <div className="seconddiv">
        <div>
          <div className="freeOnline">
            <h1 className="createfree">
              Create Free Online Store
              <br />
              <span className="merchant">with Owena Merchant</span>
            </h1>

            <p className="enables">
              Owena Merchant enables anyone to sell conveniently
              <br />
              anywhere in the world. Built with great usability that would
              <br />
              help you Manage your Store and get paid with no hassel.
            </p>
          </div>
          <div style={{ height: "170px" }}></div>
          <div
            style={{
              width: "37%",
              height: "23px",
              display: "flex",
              background: "linear-gradient(180deg, #826448 0%, #F2D77A 100%)",
              zIndex: "-1",
              position: "absolute",
              left: "8%",
              marginTop: "60px",
            }}
          ></div>

          <div className="fivedi">
            <div className="divSizeww">
              <img src={firstImage} alt="firstImage" />
              <p className="testes">
                Create an
                <br />
                account
              </p>
            </div>{" "}
            <div style={{ height: "100px" }}></div>
            <div className="divSizeww">
              <img className="imagesss" src={secondImage} alt="secondImage" />
              <p className="testes">
                Set Store
                <br />
                Location &<br />
                other Info.
              </p>
            </div>
            <div className="divSizeww">
              <img className="imagesss" src={thirdImage} alt="firstImage" />
              <p className="testes">
                Upload Products
                <br />
                available for Sale
              </p>
            </div>
            <div className="divSizeww">
              <img className="imagesss" src={fourthImage} alt="fourthImage" />
              <p className="testes">
                Receive and
                <br />
                process orders
              </p>
            </div>
            <div className="divSizeww">
              <img className="imagesss" src={firttyImage} alt="firstImage" />
              <p className="testes">
                Get paid
                <br />
                immediately
              </p>
            </div>
          </div>
        </div>
        <div className="threeimages">
          <img src={threimage} alt="threimage" />
        </div>
      </div>

      <div className="seconddivTablet">
        <div>
          <div className="freeOnlinet">
            <h1 className="createfree">
              Create Free Online Store
              <br />
              <span className="merchant">with Owena Merchant</span>
            </h1>

            <p className="enables">
              Owena Merchant enables anyone to sell conveniently
              <br />
              anywhere in the world. Built with great usability that would
              <br />
              help you Manage your Store and get paid with no hassel.
            </p>
          </div>
          <div style={{ height: "170px" }}></div>
          <div
            style={{
              width: "50%",
              height: "23px",
              display: "flex",
              background: "linear-gradient(180deg, #826448 0%, #F2D77A 100%)",
              zIndex: "-1",
              position: "absolute",
              left: "15%",
              marginTop: "60px",
            }}
          ></div>

          <div className="fivedit">
            <div className="divSizewwt">
              <img src={firstImage} alt="firstImage" />
              <p className="testes">
                Create an
                <br />
                account
              </p>
            </div>{" "}
            <div style={{ height: "100px" }}></div>
            <div className="divSizewwt">
              <img className="imagesss" src={secondImage} alt="secondImage" />
              <p className="testes">
                Set Store
                <br />
                Location &<br />
                other Info.
              </p>
            </div>
            <div className="divSizewwt">
              <img className="imagesss" src={thirdImage} alt="firstImage" />
              <p className="testes">
                Upload Products
                <br />
                available for Sale
              </p>
            </div>
            <div className="divSizewwt">
              <img className="imagesss" src={fourthImage} alt="fourthImage" />
              <p className="testes">
                Receive and
                <br />
                process orders
              </p>
            </div>
            <div className="divSizewwt">
              <img className="imagesss" src={firttyImage} alt="firstImage" />
              <p className="testes">
                Get paid
                <br />
                immediately
              </p>
            </div>
          </div>
        </div>
        <div className="imagetablet">
          <div>
            <img src={threimage} alt="threimage" style={{ width: "200px" }} />
          </div>
        </div>
      </div>

      <div className="lastofalllastDesktop">
        <div style={{ height: "50px" }}></div>
        <p className="Connecting">
          Connecting communities, Empowering Locally!
        </p>
        <p className="soon2">Coming soon on</p>
        <div className="downloadApp2">
          <div>
            <img src={AppleStore} alt="AppleStore" />
          </div>
          <div>
            <img src={GoogleStore} alt="GoogleStore" />
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
        <h1 className="promise">Subscribe for launch</h1>
        <h1 className="promise">updates</h1>
        <div style={{ height: "50px" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="Newsletterdiv Newsletterdivt">
            <div>
              <p className="Newsletter Newslettert">
                Subscribe to our Notification
              </p>
            </div>

            <div className="allinputfiedls">
              <div className="allinputfiedl allinputfiedlt">
                <input
                  type="email"
                  className="textinputfileds textinputfiledst"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleChange}
                />

                {loading ? (
                  <p
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      fontSize: "12px",
                    }}
                  >
                    sending mail
                  </p>
                ) : (
                  <div onClick={handleSubmit} className="buttons">
                    <p className="Subscribetext">Subscribe</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Oweone1">
          <div style={{ height: "50px" }}></div>
          <img className="Oweon" src={Oweone1} alt="Oweone1" />
          <div style={{ height: "50px" }}></div>

          <img src={Barcode1} alt="Oweone1" />
        </div>
      </div>

      <div className="MobileViewLandingPage">
        <div className="threeImageMobile">
          <div className="goupdivsMobile">
            <p></p>
            {/* <div style={{marginLeft: '-200px'}}>
                        <div style={{ display: 'flex', width: '34px', color: 'white', height: '34px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', backgroundColor: '#F4AA06', borderRadius: '50px' }}>
                            <h1 style={{ textAlign: 'center', }}>{currentNumber}</h1>
                        </div>
                        </div> */}

            <h1
              className="currentTextIndexm"
              dangerouslySetInnerHTML={{ __html: texts[currentTextIndex] }}
            />
            <div style={{ height: "20px" }}></div>
            <p className="currentTextIndexOwenam">
              Owena is bringing your favourite stores <br /> closer to you.
              Connecting you to sellers in
              <br /> an Express & smoother way.
            </p>
            <div style={{ height: "20px" }}></div>
            <p className="soon">Coming soon on</p>
            <div className="downloadApp">
              <div>
                <img src={AppleStore} alt="AppleStore" />
              </div>
              <div>
                <img src={GoogleStore} alt="GoogleStore" />
              </div>
            </div>
          </div>
          <div className="goupdivsMobilewidth">
            <img
              className="imagesizeMobile"
              src={groupImage}
              alt="groupImage"
            />
          </div>
        </div>
        <div className="barcodeimageMobile">
          <img className="barcodeimageMobile" src={Barcode1} alt="barcode" />
        </div>

        <div className="yellowMobile">
          <div
            style={{
              backgroundColor: "#F4AA06",
              display: "flex",
              flexDirection: "column",
              width: "600px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ height: "50px" }}></div>
            <img src={SmaillIcon} alt="SmaillIcon" />
            <div className="amazingMobile">
              <div className="amzingandshopping">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    //backgroundColor: "red",
                  }}
                >
                  {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                  <h1 className="shopping">
                    Amazing <span style={{ color: "white" }}> Shopping </span>
                  </h1>
                  {/* </div> */}
                  {/* <div> */}
                  {/* <h1 className="shoppings"> Shopping</h1> */}
                  {/* </div> */}
                </div>
                <h1 className="experience">Experience</h1>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    color: "rgba(255, 255, 255, 0.80)",
                    fontWeght: "400",
                  }}
                >
                  We are bringing High street <br /> Stores closer to you.
                  <br /> You will be able to Buy Local
                  <br /> and Multicultural goods, Pay
                  <br /> Conveniently and get your
                  <br /> orders same day.
                </p>
              </div>

              <div className="fivedivMobile">
                <div className="divSize">
                  <img src={firstImage} alt="firstImage" />
                  <p className="testes">
                    Enter your
                    <br />
                    Postcode
                  </p>
                </div>
                <div className="divSize">
                  <img
                    className="imagesss"
                    src={secondImage}
                    alt="secondImage"
                  />
                  <p className="testes">
                    Browse <br /> Categories & <br />
                    Communities
                  </p>
                </div>
              </div>
              <div className="fivedivMobile">
                <div className="divSize">
                  <img className="imagesss" src={thirdImage} alt="firstImage" />
                  <p className="testes">
                    Pick goods
                    <br /> from Stores
                    <br />
                    around you
                  </p>
                </div>
                <div className="divSize">
                  <img
                    className="imagesss"
                    src={fourthImage}
                    alt="fourthImage"
                  />
                  <p className="testes">
                    Pay
                    <br />
                    Seamlessly
                  </p>
                </div>
              </div>

              <div className="lastDiveMobile">
                <div className="divSizeMobile">
                  <img
                    className="imagesss"
                    src={firttyImage}
                    alt="firstImage"
                  />
                  <p className="testes">
                    Instant
                    <br /> Delivery
                  </p>
                </div>
              </div>
            </div>
            <img className="threethreemobile" src={three3} alt="threesimage" />
          </div>
        </div>

        <div style={{ height: "50px" }}></div>

        <div className="seconddivMobile">
          <div className="smalimg">
            <img src={smalllImage} alt="smalllImage" />
          </div>
          <div style={{ height: "30px" }}></div>
          <div className="freeOnlineMobile">
            <h1 className="createfreeMobile">Create Free Online store</h1>
            <h1 className="merchantMobile">with Owena Merchant</h1>
            <p className="enablesMobile">
              Owena Merchant enables anyone to sell convinently anywhere in the
              world. Built with great usability that would help you Manage your
              Store and get paid with no hassel.
            </p>
          </div>
          <div style={{ height: "50px" }}></div>
          <div className="smalimg">
            <img
              className="threeimagesMobile"
              src={threimage}
              alt="threimage"
            />
          </div>

          <div className="itemsdivMobile">
            <div className="fivedivMobile">
              <div className="divSize">
                <img src={firstImage} alt="firstImage" />
                <p className="testes">
                  Create an
                  <br />
                  account
                </p>
              </div>
              <div className="divSize">
                <img className="imagesss" src={secondImage} alt="secondImage" />
                <p className="testes">
                  Set Store
                  <br />
                  Location &<br />
                  other Info.
                </p>
              </div>
            </div>
            <div className="fivedivMobile">
              <div className="divSize">
                <img className="imagesss" src={thirdImage} alt="firstImage" />
                <p className="testess">
                  Upload Products
                  <br />
                  available for Sale
                </p>
              </div>
              <div className="divSize">
                <img className="imagesss" src={fourthImage} alt="fourthImage" />
                <p className="testes">
                  Receive and
                  <br />
                  process orders
                </p>
              </div>
            </div>{" "}
            <div className="smalimg">
              <div className="divSizeFiveMobile">
                <img className="imagesss" src={firttyImage} alt="firstImage" />
                <p className="testes">
                  Get paid
                  <br />
                  instantly
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="lastofalllastMobileView">
          <div style={{ height: "50px" }}></div>
          <p className="connectinss">Connecting communities,</p>
          <p className="connectinss">Empowering Locally!</p>
          <div style={{ height: "50px" }}></div>
          <p className="soonMobile">Coming soon on</p>
          <div className="downloadAppMobile">
            <div>
              <img src={AppleStore} alt="AppleStore" />
            </div>
            <div>
              <img src={GoogleStore} alt="GoogleStore" />
            </div>
          </div>
          <div style={{ height: "50px" }}></div>
          <h1 className="promiseMobile">
            Subscribe for launch
            <br />
            updates
          </h1>
          <div style={{ height: "50px" }}></div>
          <p className="NewsletterMobile">Subscribe to our Notification</p>

          <div className="allinputfiedlMobile">
            <input
              type="email"
              className="textinputfiledsMobile"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
            />

            {loading ? (
              <p
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: "12px",
                }}
              >
                sending mail
              </p>
            ) : (
              <div onClick={handleSubmit} className="buttonsMobile">
                <p className="SubscribetextMobile">Subscribe </p>
              </div>
            )}
          </div>
          <div className="Oweone1mobile">
            <div style={{ height: "70px" }}></div>
            <img className="Oweon" src={Oweone1} alt="Oweone1" />
            <div style={{ height: "70px" }}></div>
            <img className="Oweone1imagelast" src={Barcode1} alt="Oweone1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
