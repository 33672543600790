import React, {useState} from 'react';
import { NavLink } from 'react-router-dom'
import logo from '../../images/svgs/newlogo.svg'
import logoBar from '../../images/svgs/List.svg'
import close from '../../images/svgs/close.svg'
import './navbar.css'

export default function NavBar() {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar)
    }
  

  return (
    <nav style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '100', background: '#000000' }}>
       
    <div className="container ">
    <NavLink to="/">
      <div className="logo">
        <img src={logo} alt='logo'/>
      </div>
      </NavLink>
      <div className="menu-icon" onClick={handleShowNavbar}>
   
      {showNavbar ? (
           <img src={close} alt='closebar' />
          ) : (
            <img src={logoBar} alt='logobar' />
          )}

      </div>
      <div className={`nav-elements  ${showNavbar && 'active'}`}>
        <ul>
          <li>
          <div className='navHeight'>
            <NavLink to="/about_us">About Us</NavLink>
            </div>
          </li>
          <li>
          <div className='navHeight'>
            <NavLink to="/contact_us">Contact Us</NavLink>
            </div>
          </li>
    
        </ul>
      </div>
    </div>
  </nav>

  );
}
