import React, { useState } from "react";
import NavBar from "../../component/Navbar/Navbar";
import AbotUs from "../../images/owecAbouus.png";
import "../landingPage/LandingPage.css";
import GoHome from "../../images/svgs/GoHomeIcom.svg";
import Barcode1 from "../../images/barcode1.png";

import { NavLink } from "react-router-dom";
import Welcomimage from "../../images/svgs/welcomeimageaboutus.svg";
import SamllI from "../../images/svgs/samllimageabouutus2.svg";
import Connectingc from "../../images/svgs/ConnectingcommunitiesEmpoweringLoca.svg";
import GoogleStore from "../../images/svgs/apple-app-store-travel-awards-globesta.svg";
import AppleStore from "../../images/svgs/apple-app-store-travel-awards.svg";
import Oweone1 from "../../images/svgs/OWENA.svg";
import Swal from "sweetalert2";

const AboutUs = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  // const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !isValidEmail(email)) {
      Swal.fire({
        title: "Failed!",
        html: "Please enter a valid email.",
        icon: "error",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });
      return;
    }
    setLoading(true);
    // Create a POST request using the fetch API
    fetch("https://api.owenamarketplace.com/api/v1/subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setEmail("");
          Swal.fire({
            title: "Success!",
            html: `<i>${data.status}</i>`,
            icon: "success",
            allowOutsideClick: false,
          });
        }
        setLoading(false);
        // setResponseMessage(data.message);
      })
      .catch((error) => {
        // Handle any errors here
        setLoading(false);
        Swal.fire({
          title: "Failed!",
          html: `<i>${error}</i>`,
          icon: "error",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      });
  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <NavBar />
      <div className="heheight"></div>
      <div className="allAboutUsspaage">
        <div>
          <div className="div0">
            <div className="div1 divtt">
              <p></p>
              <NavLink to="/">
                <img
                  src={GoHome}
                  alt="GoHome"
                  style={{ height: "32px", marginLeft: "130px" }}
                />
              </NavLink>
              <p></p>
              <div className="threeeimages threeeimagest">
                <img src={SamllI} alt="GoHome" style={{ height: "32px" }} />
                <img
                  src={Welcomimage}
                  alt="GoHome"
                  style={{ height: "32px" }}
                />
                <img
                  src={Connectingc}
                  alt="GoHome"
                  className="connectingtablet connectinglaptop"
                />
                <p></p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <p></p>
                  <img src={SamllI} alt="GoHome" style={{ height: "32px" }} />
                </div>
              </div>
            </div>
            <div className="div2">
              <img className="dotTablet" src={AbotUs} alt="owecAbouus.png" />
            </div>
          </div>
        </div>
        <div className="dottedclass">
          <img
            className="dotTable dotTablet"
            src={Barcode1}
            alt="owecAbouus.png"
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ height: "60px" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "1316px",
              height: "auto",
              backgroundColor: "#FAFAFA",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <div className="coverAboutUstDesktop">
              <p className="pAbouttss">
                {/* <div style={{ height: '50px' }}></div> */}
                At Owena, we believe in the power of community and the
                incredible diversity that exists within it. Our mission is to
                connect local UK businesses, particularly high street stores,
                with customers who are seeking locally sourced products and
                embracing the rich tapestry of multicultural offerings. We are
                here to provide you with an innovative e-commerce platform that
                celebrates the unique spirit of local communities and brings out
                the best in people.
                <br />
                <p></p>
                We understand the importance of supporting local businesses and
                the positive impact it has on our economy and society as a
                whole. That's why we have created a mobile application that puts
                the spotlight on local businesses and their products, offering
                you an unrivaled shopping experience that is close to home. With
                just a few taps on your phone, you can explore a diverse range
                of locally sourced and multicultural items, right at your
                fingertips.
                <br />
                <p></p>
                Our team is passionate about creating an enabling environment
                for local communities. We are a dedicated group of professionals
                with backgrounds in software development, marketing, and
                business development. Our collective expertise allows us to
                craft innovative solutions that cater to your needs while
                providing local businesses with the platform they deserve to
                thrive.
                <br />
                <p></p>
                When you shop with Owena, you are not just making a purchase -
                you are making a statement. You are making a conscious choice to
                support your local community, embrace cultural diversity, and
                contribute to the growth of small-scale producers, artisans, and
                multicultural product sellers. It's a small step that can have a
                big impact on the lives of those who put their heart and soul
                into their craft.
                <br />
                <p></p>
                We have carefully designed our mobile application to ensure a
                seamless and user-friendly experience. From intuitive navigation
                to easy product discovery and secure checkout processes, we have
                thoughtfully considered every aspect to make your shopping
                journey effortless and enjoyable.
                <br />
                <p></p>
                At Owena, we are committed to providing you with the best
                possible user experience. That's why we conduct rigorous
                usability testing and market research to identify and address
                any challenges or areas for improvement. Your satisfaction is
                our top priority, and we constantly strive to exceed your
                expectations.
                <br />
                <p></p>
                Join us on this exciting journey of discovery and support.
                Together, we can build stronger communities, foster social
                cohesion, and celebrate the vibrant tapestry of cultures that
                make up the UK. Shop local, shop multicultural, and let Owena be
                your trusted companion in this enriching experience.
                <br />
                <p></p>
                Thank you for choosing Owena. Together, let's make a positive
                impact, one purchase at a time.
                <br />
                <p></p>
                <p></p>
                Sincerely,
                <br />
                The Owena Team
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="lastofalllastDesktop">
            <div style={{ height: "50px" }}></div>
            <p className="soon2">Coming soon on</p>
            <div className="downloadApp2">
              <div>
                <img src={AppleStore} alt="AppleStore" />
              </div>
              <div>
                <img src={GoogleStore} alt="GoogleStore" />
              </div>
            </div>
            <div style={{ height: "50px" }}></div>
            <h1 className="promise">Subscribe for launch</h1>
            <h1 className="promise">updates</h1>
            <div style={{ height: "50px" }}></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="Newsletterdiv Newsletterdivt">
                <div>
                  <p className="Newsletter Newslettert">
                    Subscribe to our Notification
                  </p>
                </div>

                <div className="allinputfiedls">
                  <div className="allinputfiedl allinputfiedlt">
                    <input
                      className="textinputfileds textinputfiledst"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleChange}
                      type="email"
                    />
                    {loading ? (
                      <p
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          alignContent: "center",
                          fontSize: "12px",
                        }}
                      >
                        sending mail
                      </p>
                    ) : (
                      <div onClick={handleSubmit} className="buttons">
                        <p className="Subscribetext">Subscribe</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="Oweone1">
              <div style={{ height: "50px" }}></div>
              <img className="Oweon" src={Oweone1} alt="Oweone1" />
              <div style={{ height: "50px" }}></div>
              <img src={Barcode1} alt="Oweone1" />
            </div>
          </div>
        </div>
      </div>

      <div className="allAboutUsspaageMobileView">
        <div className="heheight"></div>
        <div style={{ backgroundColor: "#F4AA06", width: "100%" }}>
          <NavLink to="/">
            <img src={GoHome} alt="GoHome" style={{ height: "32px" }} />
          </NavLink>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={SamllI} alt="GoHome" style={{ height: "32px" }} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h1 style={{ color: "#FFDF73", margin: "0px" }}>
                Welcome <span style={{ color: "#FFFFFF" }}>to Owena</span>
              </h1>
            </div>
          </div>
          <p style={{ color: "#FFFFFF", textAlign: "center", margin: "0px" }}>
            Connecting communities, Empowering Locally!
          </p>
          <img
            src={SamllI}
            alt="GoHome"
            style={{ height: "32px", marginLeft: "170px" }}
          />
          <div style={{ justifyContent: "center", display: "flex" }}>
            <img src={AbotUs} alt="owecAbouus.png" style={{ width: "100%" }} />
          </div>
        </div>
        <img src={Barcode1} alt="owecAbouus.png" style={{ width: "100%" }} />
        <div
          style={{
            display: "flex",
            backgroundColor: "#FAFAFA",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <p
              style={{
                color: "#000000",
                opacity: "0.6",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "justify",
              }}
            >
              At Owena, we believe in the power of community and the incredible
              diversity that exists within it. Our mission is to connect local
              UK businesses, particularly high street stores, with customers who
              are seeking locally sourced products and embracing the rich
              tapestry of multicultural offerings. We are here to provide you
              with an innovative e-commerce platform that celebrates the unique
              spirit of local communities and brings out the best in people.
              <br />
              <p></p>
              We understand the importance of supporting local businesses and
              the positive impact it has on our economy and society as a whole.
              That's why we have created a mobile application that puts the
              spotlight on local businesses and their products, offering you an
              unrivaled shopping experience that is close to home. With just a
              few taps on your phone, you can explore a diverse range of locally
              sourced and multicultural items, right at your fingertips.
              <br />
              <p></p>
              Our team is passionate about creating an enabling environment for
              local communities. We are a dedicated group of professionals with
              backgrounds in software development, marketing, and business
              development. Our collective expertise allows us to craft
              innovative solutions that cater to your needs while providing
              local businesses with the platform they deserve to thrive.
              <br />
              <p></p>
              When you shop with Owena, you are not just making a purchase - you
              are making a statement. You are making a conscious choice to
              support your local community, embrace cultural diversity, and
              contribute to the growth of small-scale producers, artisans, and
              multicultural product sellers. It's a small step that can have a
              big impact on the lives of those who put their heart and soul into
              their craft.
              <br />
              <p></p>
              We have carefully designed our mobile application to ensure a
              seamless and user-friendly experience. From intuitive navigation
              to easy product discovery and secure checkout processes, we have
              thoughtfully considered every aspect to make your shopping journey
              effortless and enjoyable.
              <br />
              <p></p>
              At Owena, we are committed to providing you with the best possible
              user experience. That's why we conduct rigorous usability testing
              and market research to identify and address any challenges or
              areas for improvement. Your satisfaction is our top priority, and
              we constantly strive to exceed your expectations.
              <br />
              <p></p>
              Join us on this exciting journey of discovery and support.
              Together, we can build stronger communities, foster social
              cohesion, and celebrate the vibrant tapestry of cultures that make
              up the UK. Shop local, shop multicultural, and let Owena be your
              trusted companion in this enriching experience.
              <br />
              <p></p>
              Thank you for choosing Owena. Together, let's make a positive
              impact, one purchase at a time.
              <br />
              <p></p>
              <p></p>
              Sincerely,
              <br />
              The Owena Team
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="lastofalllastMobileView">
          <div style={{ height: "50px" }}></div>
          <p className="soonMobile">Coming soon on</p>
          <div style={{ height: "30px" }}></div>
          <div className="downloadAppMobile">
            <div>
              <img src={AppleStore} alt="AppleStore" />
            </div>
            <div>
              <img src={GoogleStore} alt="GoogleStore" />
            </div>
          </div>
          <div style={{ height: "30px" }}></div>
          <h1 className="promiseMobile">
            Subscribe for launch
            <br />
            updates
          </h1>
          <div style={{ height: "30px" }}></div>
          <p className="NewsletterMobile">Subscribe to our Notification</p>

          <div className="allinputfiedlMobile">
            <input
              className="textinputfiledsMobile"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
              type="email"
            />

            {loading ? (
              <p
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: "12px",
                }}
              >
                sending mail
              </p>
            ) : (
              <div onClick={handleSubmit} className="buttonsMobile">
                <p className="SubscribetextMobile">Subscribe</p>
              </div>
            )}
          </div>
          <div className="Oweone1mobile">
            <div style={{ height: "70px" }}></div>
            <img
              className="Oweon"
              src={Oweone1}
              alt="Oweone1"
              style={{ width: "100%" }}
            />
            <div style={{ height: "70px" }}></div>
            <img
              className="Oweone1imagelast"
              src={Barcode1}
              alt="Oweone1"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
