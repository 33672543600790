import React from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LandingPage from './Pages/landingPage/LandingPage.js'
import NoPageFound from './Pages/NoPageFound/NotFoundPage.js'
import ContactUs from './Pages/contact-Us/Contact_Us.js';
import AboutUs from './Pages/About-Us/About_Us.js';
function App() {
  return (
    <div>
     <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/*" element={<NoPageFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
